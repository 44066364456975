interface CardProps {
	image: string;
	title: string;
	subTitle: string;
	desc?: string;
}

export default function Card(props: CardProps) {
	const {image, title, subTitle, desc = ""} = props;
	return (
		<div className="w-full lg:w-[420px] mb-8 cardbox">
			<div className="h-full bg-white dark:bg-gray-700 mx-4 rounded-lg shadow-xl hover:shadow-2xl transition-all border border-gray-200">
				<div className="rounded-t-lg h-72 w-full overflow-hidden">
					<img src={image} className="w-full h-full object-cover" />
				</div>
				<div className="px-4">
					<h1 className="font-bold mt-4 text-xl text-secondary dark:text-gray-100">{title}</h1>
					<h3 className="text-primary-500 dark:text-secondary">{subTitle}</h3>
					<p className="text-gray-800 dark:text-gray-300 my-4">{desc}</p>
				</div>
			</div>
		</div>
	);
}
