// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { useEffect, useState } from "react";
import "react-datepicker/dist/react-datepicker.css";
import { Images } from "./../../assets";
import Icon from "./../../components/common/icon";
import "./../../styles/booking.css";

interface BookingProps {
	className?: string;
}

const BASE_URL = "https://hotels.eglobe-solutions.com/hotelmayankresidency/booking/hotels/hotel-mayank-residency-new-delhi?nights=1&checkIn="

export function Booking({className = ""}: BookingProps) {
	const [isMobile, setIsMobile] = useState(true);
	const [date, setDate] = useState(new Date());
	const formattedDate = date.toLocaleDateString("en-US", {
		day: "numeric",
		month: "short",
		year: "numeric"
	});

	const [bookingURL, setBookURL] = useState(BASE_URL)
	useEffect(()=>{
		const url = BASE_URL + formattedDate;
		// console.log("Booking URL:", url)
		setBookURL(url)
	},[formattedDate])

	useEffect(() => {
		const mql = window && window.matchMedia && window.matchMedia("(min-width: 768px)");
		const onChange = () => setIsMobile(!mql.matches);
		if (mql) {
			mql.addEventListener("change", onChange);
			setIsMobile(!mql.matches);
		}		
		return () => mql && mql.removeEventListener("change", onChange);
	}, []);

	return (
		<div className={`booking ${className}`}>
			<div className="max-w-7xl mx-auto px-6">
				<div
					className="items-wrapper relative flex flex-col justify-center items-center lg:flex-row lg:items-end"
					style={{
						backgroundPosition: "center 70%",
						backgroundRepeat: `${isMobile ? "repeat-y" : "repeat-x"}`,
						backgroundImage: `url(${isMobile ? Images.HandDrawnWide : Images.HandDrawn})`,
						backgroundSize: `${isMobile ? "100%" : "10% 130%"}`
					}}>
					<div className="mx-2 my-1 text-center">
						<h1 className="font-bold text-primary">Want to Book Room: </h1>
					</div>
					<div>						
						<a
							className="book-now"
							target="_blank"
							href={bookingURL}
							rel="noreferrer">
							<div className="button">
							<Icon name={"fa-solid fa-paper-plane" as IconProp} size={"sm"} />
							<div className="whitespace-nowrap mx-3 font-bold">Book Room Now</div>
						</div>
						</a>
					</div>
				</div>
			</div>
		</div>
	);
}
