import {Wrapper} from "@googlemaps/react-wrapper";
import React, {ReactElement, useEffect, useRef, useState} from "react";
import useDeepCompareEffect from "use-deep-compare-effect";
import {Icons} from "../../assets";

interface MapProps {
	center: google.maps.LatLngLiteral;
	zoom: number;
	locations: MarkerProps[];
	children?: React.ReactNode;
}

interface GoogleMapProps extends google.maps.MapOptions {
	apiKey: string;
	options: MapProps;
}

interface MarkerProps {
	id: string;
	location: google.maps.LatLngLiteral;
	text: string;
}

const Map = (props: MapProps): ReactElement => {
	const {children, locations, ...options} = props;
	const mapRef = useRef<HTMLDivElement | null>(null);
	const [map, setMap] = useState<google.maps.Map>();
	const [markers, setMarkers] = useState<google.maps.Marker[]>([]);

	let infoWindow: google.maps.InfoWindow;

	useEffect(() => {
		if (mapRef.current && !map) {
			setMap(new window.google.maps.Map(mapRef.current, options));
		}
	}, [mapRef]);

	useDeepCompareEffect(() => {
		if (map) {
			map.setOptions(options);
			if (locations.length) addMarkers();
			else clearMarkers();

			if (locations.length > 0 && markers.length > 0) {
				onMarkerSelectHandler(markers[0], locations[0]);
			}
		}
	}, [map, options]);

	const clearMarkers = () => {
		// console.log("clearing markers");
		markers.forEach((marker, i) => {
			marker.setMap(null);
			markers.splice(i, 1);
		});
	};

	const onMarkerSelectHandler = (marker: google.maps.Marker, item: MarkerProps) => {
		// console.log(marker, item);
		const {text} = item;
		if (infoWindow) {
			infoWindow.close();
		}

		infoWindow = new google.maps.InfoWindow({
			content: `<div style="padding:4px; display:flex; flex-direction: column; align-items:center; justify-content:space-between">
				<h2 className='font-semibold text-lg mb-2'>
				${text}
				</h2>
			</div>
			`
		});

		infoWindow.open({
			anchor: marker,
			map,
			shouldFocus: true
		});
	};

	const addMarkers = () => {
		map &&
			locations.forEach((item, i) => {
				const {id, location, text} = item;
				const icon = {
					url: Icons.MarkerHMR,
					scaledSize: new google.maps.Size(35, 50), // scaled size
					origin: new google.maps.Point(0, 0), // origin
					anchor: new google.maps.Point(17, 50) // anchor
				};

				const marker = new window.google.maps.Marker({
					position: location,
					// icon: Icons.MarkerHMR
					icon: icon
				});
				marker.setMap(map);
				marker.addListener("click", () => onMarkerSelectHandler(marker, item));
				setMarkers(markers.concat([marker]));

				// const itemView = document.getElementById(`item-view-${id}`);

				//   window.google.maps.event.addDomListener(clinicView, "click", () => {
				//     infoWindow.setContent(
				//       `<div className='flex flex-col content-start p-2'>` +
				//         `<h2 className='font-semibold text-lg mb-2'>` +
				//         `${location.locationName}` +
				//         `</h2>` +
				//         `<p>${line1}</p>` +
				//         `<p>${city}, ${state} ${postcode}</p>`
				//     );

				//     infoWindow.open({
				//       anchor: marker,
				//       map,
				//       shouldFocus: true,
				//     });
				//   });
				// marker.addListener("click", () => {
				// 	// selectedLocation = location;
				// 	infoWindow.setContent(
				// 		"<div className='flex flex-col content-start p-2'>" +
				// 			"<h2 className='font-semibold text-lg mb-2'>" +
				// 			`${location.locationName}` +
				// 			"</h2>" +
				// 			`<p>${line1}</p>` +
				// 			`<p>${city}, ${state} ${postcode}</p>`
				// 	);

				// 	infoWindow.open({
				// 		anchor: marker,
				// 		map,
				// 		shouldFocus: true
				// 	});
				// });
			});
	};

	return (
		<>
			<div ref={mapRef} id="map" style={{height: "100%", width: "100%"}} />
		</>
	);
};

const GoogleMap = (props: GoogleMapProps) => {
	const {apiKey, options} = props;
	return (
		<Wrapper apiKey={apiKey}>
			<Map {...options}></Map>
		</Wrapper>
	);
};

export default GoogleMap;

// const Marker: React.FC<google.maps.MarkerOptions> = (options) => {
// 	const [marker, setMarker] = useState<google.maps.Marker>();

// 	useEffect(() => {
// 		if (!marker) {
// 			setMarker(new google.maps.Marker());
// 		}
// 		// remove marker from map on unmount
// 		return () => {
// 			if (marker) marker.setMap(null);
// 		};
// 	}, [marker]);

// 	useEffect(() => {
// 		if (marker) {
// 			marker.setOptions(options);
// 		}
// 	}, [marker, options]);

// 	return null;
// };

// const render = (status: Status) => {
// 	if (status === Status.FAILURE) return <div>There is something went wrong. Please check your Google Maps API</div>;
// 	return (
// 		<div>
// 			<img src={Images.Loader} />
// 		</div>
// 	);
// };
