import BackWithContent from "./back-with-content";
import SimpleWithContent from "./simple-with-content";
import SideImage from "./side-image";
import Simple from "./simple";

export {BackWithContent, SideImage, Simple, SimpleWithContent};
export default {
	BackWithContent,
	SideImage,
	Simple,
	SimpleWithContent
};
