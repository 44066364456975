import Images from "../../assets/images/index";
import { Booking } from "../../components/booking";
import Card from "../../components/common/card";
import Footer from "../../components/footer";
import Masterheads from "../../components/masterheads";
import "./../../styles/amenities.css";

export default function Amenities() {
	return (
		<>
			<div className="page">
				<div className="wrapper">
					<section className="relative w-full">
						<Masterheads.SimpleWithContent className="h-[60vh] lg:h-[80vh]" />
						<Booking />
					</section>

					<section>
						<div className="relative pt-24 pb-12 w-full">
							<div className="max-w-7xl mx-auto">
								<div className="flex flex-row flex-wrap items-stretch justify-start space-x-1">
									<Card
										image={Images.FreeWifi}
										title="FREE WiFi STAY CONNECTED"
										subTitle="Hi-Speed Free WiFi connection available 24x7"
										desc="A Member who books a Stay will receive complimentary standard in-room hi-speed internet access during the Stay completely free so that you remain connected 24x7"
									/>
									<Card
										image={Images.CleanedAcRoom}
										title="WELL CLEANED A/C ROOMS"
										subTitle="Cleaned A/C rooms for better comfort"
										desc="Most of the peoples in their holidays or trips get infected. We extra care our guests and provides well cleaned and highly efficient airconditioned rooms, so that our guests can enjoy their trips without any worry."
									/>
									<Card
										image={Images.AirportPickup}
										title="AIRPORT PICKUP AND DROP"
										subTitle="Pickup and drop from the Airport"
										desc="Hotel have their own vehicles to provide instant pick-up and drop facility to their guests from Airport."
									/>

									<Card
										image={Images.Currency}
										title="CURRENCY EXCHANGE"
										subTitle="Convert your currency to Indian 'Rupee'"
										desc="In India 'Rupee' is used as the currency. It is better to keep indian currency for local purchase or other payments. So guests can exchange their money like Dollar, Euro etc. to Indian 'Rupee'."
									/>

									<Card
										image={Images.ValetParking}
										title="VALET PARKING"
										subTitle="Get your vehicle parked in safe &amp; private parking"
										desc="Hotel have a safe and sound underground parking for personal vehicles. So no need to worry about parking."
									/>

									<Card
										image={Images.LaundryOnRequest}
										title="LAUNDRY ON REQUEST"
										subTitle="Get your laundry clean on requests"
										desc="We also provide the laundry facility on guests requests. So no need to travel with heavy luggage for their trips."
									/>

									<Card
										image={Images.DoctorOnCall}
										title="DOCTOR ON CALL"
										subTitle="Any medical urgency, Doctors available on call"
										desc="If our guests are not feeling well and not able to visit clicnic or hospitals. We arrange efficient physicians and specialists to provide effective treatment."
									/>

									<Card
										image={Images.LockerFacility}
										title="LOCKER FACILITY"
										subTitle="Locker facility for safety of your belongings"
										desc="We have locker facility in every room for safety of our guests's valueables."
									/>

									<Card
										image={Images.PowerBackup}
										title="POWER BACKUP"
										subTitle="Enjoy your stay uninterrupted"
										desc="Our guests can enjoy their stay uninterrupted, so hotel provides the high capacity and noise free 100% power backup which gets in effect immediately if power cut."
									/>
								</div>
							</div>
						</div>
					</section>

					<section>
						<Footer />
					</section>
				</div>
			</div>
		</>
	);
}
