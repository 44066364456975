import BellShake from "./bell-shake.mp3";
import Click from "./click.mp3";
import Swish from "./swish.mp3";

const Sounds = {
	BellShake,
	Click,
	Swish
};

export default Sounds;
