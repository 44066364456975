import { useEffect, useState } from "react";
import { Images } from "../../assets";
import { Booking } from "./../../components/booking/index";
import Footer from "./../../components/footer/index";
import MasterHeads from "./../../components/masterheads";
import "./../../styles/rooms.css";
import ImageViewer, { IGalleryItem } from "./image-viewer";

const GalleryItems: IGalleryItem[] = [
	{image: Images.FamilyRoom1},
	{image: Images.FamilyRoom2},
	{image: Images.FamilyRoom3},
	{image: Images.FamilyRoom4},
	{image: Images.FamilyRoom5},
	{image: Images.FamilyRoom6},
	{image: Images.FamilyRoom7},
	{image: Images.FamilyRoom8},
	{image: Images.DeluxeRoom1},
	{image: Images.DeluxeRoom2},
	{image: Images.DeluxeRoom3},
	{image: Images.DeluxeRoom4},
	{image: Images.DeluxeRoom5},
	{image: Images.DeluxeRoom6},
	{image: Images.DeluxeRoom7},
	{image: Images.DeluxeRoom8},
	{image: Images.DeluxeRoom9},
	{image: Images.DeluxeRoom10},
	{image: Images.DeluxeRoom11},
	{image: Images.Building},
	{image: Images.Lobby},
	{image: Images.Enterance}
];
export default function Gallery() {
	const [isMobile, setIsMobile] = useState(true);
	const [items, setItems] = useState<IGalleryItem[][]>([]);
	useEffect(() => {
		const mql = window && window.matchMedia && window.matchMedia("(min-width: 768px)");
		const onChange = () => setIsMobile(!mql.matches);
		if (mql) {
			mql.addEventListener("change", onChange);
			setIsMobile(!mql.matches);
		}

		return () => mql && mql.removeEventListener("change", onChange);
	}, []);

	useEffect(() => {
		const col = isMobile ? 2 : 3;
		const total = GalleryItems.length
		const row = Math.ceil(total / col);
		const items = [];
		// console.log("Total:",total)
		for (let i = 0; i < row; i++) {
			const images = [];
			for (let j = 0; j < col; j++) {
				const index = (i*col)+j
				// console.log("Index:",index)
				if(index < total) {
					const element = GalleryItems[index];
					if (element) images.push(element);
				}
			}
			items.push(images);
		}
		setItems(items);
	}, [isMobile]);

	return (
		<>
			<div className="page">
				<div className="wrapper">
					<section className="relative w-full">
						<MasterHeads.SimpleWithContent className="h-[60vh] lg:h-[80vh]" />
						<Booking />
					</section>

					<section>
						<div className="relative pt-24 pb-12 w-full dark:bg-gray-700">
							<div className="max-w-7xl mx-auto">
								<div className="flex flex-row flex-wrap items-stretch justify-start">
									{items.map((item, index) => {
										return <ImageViewer key={index} className="h-[60vh]" images={item} />;
									})}
								</div>
							</div>
						</div>
					</section>

					<section>
						<Footer />
					</section>
				</div>
			</div>
		</>
	);
}
