import React, { useEffect, useRef } from "react";
import { useTheme } from "../../hooks/useDarkMode";
import * as Device from "../../hooks/useDevice";
import { Images } from "./../../assets";
import "./../../styles/card3d.css";
interface Card3DProps {
	style?: object;
	className?: string;
	children?: React.ReactNode; // 👈️ added type for children
}

export default function Card3D(props: Card3DProps) {
	const {className, style, children} = props;
	const ref = useRef<HTMLElement>(null);
	const [isDark] = useTheme();
	const [isDesktop, mediaQueryList, matchMedia] = Device.useMediaQuery({query:Device.DESKTOP_MQ});
	const THRESHOLD = 20;

	useEffect(() => {
		// console.log("isDesktop::",isDesktop, mediaQueryList, matchMedia);
		if (ref && ref.current && isDesktop) {
			const card = ref.current;
			const motionMatchMedia = matchMedia("(prefers-reduced-motion)");
			if (!motionMatchMedia.matches) {
				card.addEventListener("mousemove", handleHover);
				card.addEventListener("mouseleave", handleLeave);
			}
		}
	}, [isDesktop, matchMedia, mediaQueryList]);

	const handleHover = (e: MouseEvent) => {
		const {clientX, clientY, currentTarget} = e;
		if (currentTarget) {
			const element = currentTarget as HTMLElement;
			const {clientWidth, clientHeight, offsetLeft, offsetTop} = element;
			const horizontal = (clientX - offsetLeft) / clientWidth;
			const vertical = (clientY - offsetTop) / clientHeight;

			// const rotateX = 0;
			const rotateX = ((0.5 - horizontal) * THRESHOLD).toFixed(2);
			// console.log(horizontal, rotateX);
			// const rotateY = 0;
			const rotateY = ((vertical - 1.5) * THRESHOLD).toFixed(2);
			// console.log(vertical);
			element.style.transform = `perspective(${clientWidth}px) rotateX(${rotateY}deg) rotateY(${rotateX}deg) scale3d(1, 1, 1)`;
		}
	};

	const handleLeave = (e: MouseEvent) => {
		const {currentTarget} = e;
		if (currentTarget) {
			const element = currentTarget as HTMLElement;
			element.style.transform = `perspective(${element.clientWidth}px) rotateX(0deg) rotateY(0deg)`;
		}
	};

	return (
		<article
			ref={ref}
			className={`card3d shadow-lg ${className}`}
			style={{
				backgroundImage: `url(${ isDark ? Images.HandDrawnGray : Images.HandDrawn})`,
				backgroundPosition: "center",
				backgroundSize: "100%",
				...style
			}}>
			<div className="content">{children}</div>
		</article>
	);
}
