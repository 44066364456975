import type {RouteProps} from "./index";

export default function MobileMenu({routes = []}: RouteProps) {
	// <!-- Mobile menu, show/hide based on menu state. -->
	return (
		<div id="mobile-menu" className="sm:hidden fixed top-16 z-10 left-0 right-0 bottom-0 bg-gray-900 ">
			<div className="w-full h-full px-2 pt-2 pb-3 space-y-1 ">
				{/* <!-- Current: "bg-gray-900 text-white", Default: "text-gray-300 hover:bg-gray-700 hover:text-white" --> */}
				{routes.map(({title, url}, index) => {
					return (
						<a
							key={index}
							href={url}
							className="bg-gray-100 dark:bg-gray-700 dark:hover:bg-gray-900 text-gray-700 hover:bg-gray-300 dark:text-white block px-3 py-2 rounded-md text-base font-medium"
							aria-current="page">
							{title}
						</a>
					);
				})}
			</div>
		</div>
	);
}
