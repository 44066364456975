import { useEffect, useState } from "react";

interface MediaQueryProps {
	query?:string;	
}

export const MOBILE_MQ = "(min-width=768px)";
export const TABLET_MQ = "(min-width: 768px) and (max-width: 991px)";
export const DESKTOP_MQ = "(min-width: 992px)";

export function useMediaQuery({query=MOBILE_MQ}:MediaQueryProps) {
	const matchMedia = window && window.matchMedia;
    const mediaQueryList = matchMedia(query);
	const [isMatches, setIsMatches] = useState<boolean>();
	
	useEffect(() => {
		const onChange = () => setIsMatches(!mediaQueryList.matches);
		if (mediaQueryList) {
			mediaQueryList.addEventListener("change", onChange);
			setIsMatches(mediaQueryList.matches);
		}
		return () => mediaQueryList && mediaQueryList.removeEventListener("change", onChange);
	}, [query, mediaQueryList, matchMedia]);

	return [isMatches, mediaQueryList, matchMedia] as const;
}
