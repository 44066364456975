import {Images} from "./../../assets";

export function TrustQuality() {
	return (
		<div className="trust-quality w-full pt-24 pb-16 dark:bg-gray-700">
			<div className="max-w-7xl mx-auto px-2 sm:px-6 lg:px-8">
				<h3 className="font-bold text-secondary">ROOMS</h3>
				<h2 className="font-extrabold text-3xl text-primary dark:text-white flex flex-col mb-6">
					Spacious &amp; Luxurious Rooms
				</h2>
				<TrustQualityItem
					image1={Images.HomeSpace1}
					image2={Images.HomeSpace2}
					title="BIG"
					subTitle="Spacious Rooms"
					desc={`The most spacious rooms you will ever see in our hotel.
					<br />
					Equipped with span and warm beds.`}
				/>

				<TrustQualityItem
					image1={Images.HomeBig1}
					image2={Images.HomeBig2}
					title="PREMIUM"
					subTitle="Luxurious Rooms"
					desc={`Biggest rooms with premium comfort in our hotel are fully
					<br />
					equipped with luxurious features.`}
				/>
			</div>
		</div>
	);
}
interface TrustQualityItemProps {
	image1: string;
	image2: string;
	title: string;
	subTitle: string;
	desc: string;
	className?: string;
	reverse?: boolean;
}
const TrustQualityItem = (props: TrustQualityItemProps) => {
	const {image1, image2, title, subTitle, desc, className = "", reverse = false} = props;
	return (
		<div
			className={`flex space-x-4 space-y-2 items-stretch justify-between mb-8 ${
				reverse ? "flex-col-reverse lg:flex-row-reverse" : "flex-col lg:flex-row"
			} ${className}`}>
			<div className="flex-1 flex flex-row justify-between space-x-1">
				<div className="flex-1">
					<img className="w-full h-auto" src={image1} />
				</div>
				<div className="flex-1">
					<img className="w-full h-auto" src={image2} />
				</div>
			</div>
			<div className="relative flex-1 py-5 flex flex-row items-center space-between">
				<div className="space-y-2 pr-4">
					<div className="text-lg font-bold text-secondary">{title}</div>
					<div className="font-bold text-base dark:text-white">{subTitle}</div>
					<p className="text-gray-600 dark:text-gray-300" dangerouslySetInnerHTML={{__html: desc}}></p>
				</div>
				<span className={`bg-secondary w-1 absolute top-0 bottom-0 ${reverse ? "right-4" : "right-0"}`}></span>
			</div>
		</div>
	);
};
