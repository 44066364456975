import {IconProp, SizeProp} from "@fortawesome/fontawesome-svg-core";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {useMemo, useState} from "react";
import {Sounds} from "./../../assets";
type IconProps = {
	size?: SizeProp | number;
	type?: "FontAwesome" | "Custom" | "Image";
	className?: string;
	name: IconProp | string;
	toggleClass?: string;
	hoverSound?: string;
	clickSound?: string;
	children?: React.ReactNode; // 👈️ added type for children
	mouseEvents?: boolean;
	// onClick?:React.MouseEventHandler<HTMLButtonElement>;
};

export default function Icon(props: IconProps) {
	const {type = "FontAwesome", size = "xl", toggleClass, name, className} = props;
	const {mouseEvents = false, hoverSound = Sounds.Swish, clickSound = Sounds.Click} = props;
	const {children} = props;
	const [isHovering, setIsHovering] = useState(false);

	const audioClick = useMemo(() => {
		return new Audio(clickSound);
	}, [clickSound]);

	const audioHover = useMemo(() => {
		return new Audio(hoverSound);
	}, [hoverSound]);

	const onClickHandler = () => {
		if (mouseEvents) {
			if (audioClick) audioClick.play();
		}
	};
	if (type === "FontAwesome")
		return (
			// <span onClick={onClickHandler}>
			<FontAwesomeIcon
				onMouseUp={onClickHandler}
				onMouseOver={() => {
					if (mouseEvents) {
						if (audioHover) audioHover.play();
						setIsHovering(true);
					}
				}}
				onMouseOut={() => {
					if (mouseEvents) setIsHovering(false);
				}}
				className={`${className} ${isHovering ? toggleClass : ""}`}
				icon={name as IconProp}
				size={size as SizeProp}
			/>
			// </span>
		);
	else if (type === "Custom")
		return (
			<div
				onMouseUp={onClickHandler}
				onMouseOver={() => {
					if (audioHover && mouseEvents) audioHover.play();
					setIsHovering(true);
				}}
				onMouseOut={() => setIsHovering(false)}
				className={`inline-flex items-center justify-center ${className} ${isHovering ? toggleClass : ""}`}>
				{children}
			</div>
		);
	else
		return (
			<img
				onMouseUp={onClickHandler}
				onMouseOver={() => {
					if (audioHover && mouseEvents) audioHover.play();
					setIsHovering(true);
				}}
				onMouseOut={() => setIsHovering(false)}
				src={name as string}
				className={`${className}`}
			/>
		);
}
