import { SizeProp } from "@fortawesome/fontawesome-svg-core";
import { useMemo } from "react";
import { BackgroundStyle } from "../../assets/images";
import Card3D from "../../components/common/card3d";
import { useTheme } from "../../hooks/useDarkMode";
import * as Device from "../../hooks/useDevice";
import { Icons, Images } from "./../../assets";
import Icon from "./../../components/common/icon";

export function Amenities() {
	const [isMobile] = Device.useMediaQuery({query:Device.MOBILE_MQ});
	
	return (
		<div className="amenities w-full py-6 lg:py-16 bg-black overflow-hidden">
			<div
				className="absolute w-full h-full left-0 top-0 opacity-30"
				style={{
					backgroundColor: "rgba(0,0,0,1)",
					backgroundImage: `url(${Images.AmenitiesBG})`,
					backgroundRepeat: "no-repeat",
					backgroundPosition: "50% 50%",
					backgroundSize: "cover"
				}}></div>	
			<div className="relative max-w-7xl mx-auto px-2 sm:px-6 lg:px-8 space-y-6">
				<div className="flex-1 flex-col">
					<h3 className="font-bold text-secondary">Amenities</h3>
					<h2 className="font-extrabold text-2xl mb-4 lg:text-3xl text-white dark:text-white lg:mb-10">
						We bring you the finest service
					</h2>

					<div className="relative w-full flex flex-row flex-wrap justify-between items-stretch">
						<Feature
							iconName="wifi"
							title="FREE WIFI TO STAY CONNECTED"
							desc="Hi-Speed Free WiFi connection available 24x7"
							iconSize={isMobile ? "1x" : "2x"}
						/>
						<Feature
							iconName="plane-departure"
							title="AIRPORT PICKUP AND DROP"
							desc="Absolutely free pickup and drop from the Airport"
							iconSize={isMobile ? "1x" : "2x"}
						/>
						<Feature
							iconName="temperature-arrow-down"
							title="WELL CLEANED A/C ROOMS"
							desc="Cleaned A/C rooms for better comfort"
							iconSize={isMobile ? "1x" : "2x"}
						/>
						<Feature
							iconName="indian-rupee-sign"
							title="CURRENCY EXCHANGE"
							desc="Get convert your currency to Indian currency (Rupee)"
							iconSize={isMobile ? "1x" : "2x"}
						/>

						<Feature
							iconName="car"
							title="VALET PARKING"
							desc="Get your vehicle parked in safe &amp; private parking"
							iconSize={isMobile ? "1x" : "2x"}
						/>

						<Feature
							iconType="Image"
							iconName={Icons.WashingMachineWhite}
							iconStyle={"w-10 h-auto"}
							title="LAUNDRY ON REQUEST"
							desc="Get your laundry clean on requests"
							iconSize={isMobile ? "1x" : "2x"}
						/>

						<Feature
							iconName="user-doctor"
							title="DOCTOR ON CALL"
							desc="In case of any medical need Doctors available on call"
							iconSize={isMobile ? "1x" : "2x"}
						/>

						<Feature
							iconName="vault"
							title="LOCKER FACILITY"
							desc="Locker facility for safety of your belongings"
							iconSize={isMobile ? "1x" : "2x"}
						/>
					</div>
				</div>
			</div>
		</div>
	);
}

interface FeatureProps {
	className?: string;
	title: string;
	desc: string;
	iconName: string;
	iconType?: "FontAwesome" | "Custom" | "Image";
	iconSize?: SizeProp | number;
	iconStyle?: string;
}
const Feature = (props: FeatureProps) => {
	const {className = "", title, desc} = props;
	const {iconName, iconType = "FontAwesome", iconSize = "2x", iconStyle = "text-white"} = props;
	const [isMobile] = Device.useMediaQuery({query:Device.MOBILE_MQ});
	const [isDark] = useTheme();

	const wrapperBackgroundStyle = useMemo(()=>{
		// console.log("Mode::",isDark, theme)
		return isDark ? BackgroundStyle({
			image: `url( ${(isMobile ? Images.DarkSky : Images.DarkSky)})`,
			position: "center",
			repeat: `${isMobile ? "repeat-y" : "repeat-x"}`,
			size: `${isMobile ? "100%" : "100%"}`
		}) : BackgroundStyle({
			image: `url( ${(isMobile ? Images.HandDrawnWide : Images.HandDrawn)})`,
			position: "center 70%",
			repeat: `${isMobile ? "repeat-y" : "repeat-x"}`,
			size: `${isMobile ? "100%" : "10% 130%"}`
		})
	},[isDark, isMobile]);

	return (
		<div className="w-1/2 lg:w-72 p-2">
			<Card3D className="w-full h-full">
				<div className={`feature relative ${className}`}>
					<div className="container hover:scale-[0.95] flex flex-col items-center justify-between p-2 lg:p-4">
						<div className="rounded-full bg-secondary dark:bg-primary w-10 h-10 lg:w-16 lg:h-16 flex items-center justify-center p-4 mx-auto">
							<Icon type={iconType} name={iconName} size={iconSize} className={iconStyle} />
						</div>
						<div className="lg:h-20 flex items-center justify-center">
							<h3 className="font-bold text-sm lg:text-lg text-primary dark:text-secondary text-center my-2 lg:my-4 leading-tight">
								{title}
							</h3>
						</div>
						<p className="text-xs lg:text-base text-gray-500 mb-3 text-center">{desc}</p>
					</div>
				</div>
			</Card3D>
		</div>
	);
};
