import { useState } from "react";
import "./../../styles/navbar.css";
import MobileMenu from "./mobile-menu";
import MobileMenuButton from "./mobile-menu-button";
import PrimaryNav from "./primary-nav";
import SecondaryNav from "./secondary-nav";

export type Route = {
	title: string;
	url: string;
};

export type RouteProps = {
	routes: Route[];
};

export type NavbarProps = {
	routes?: Route[];
	userRoutes?: Route[];
};

const RoutesList = [
	{title: "Home", url: "/"},
	{title: "Amenities", url: "/amenities"},
	{title: "Rooms And Tariffs", url: "/rooms-and-tariffs"},
	{title: "Gallery", url: "/gallery"},
	{title: "Location", url: "/location"},
	{title: "Contact Us", url: "/contact-us"}
];

const UserRoutes = [
	{title: "Profile", url: "/profile"},
	{title: "Settings", url: "/settings"},
	{title: "Sign Out", url: "/signout"}
];

export default function Navbar({routes = RoutesList, userRoutes = UserRoutes}: NavbarProps) {
	const [showMobileMenu, setShowMobileMenu] = useState(false);
	return (
		<nav className="sticky navbar top-0 z-30">
			<div className="max-w-7xl mx-auto px-2 sm:px-6 lg:px-0">
				<div className="relative flex items-center justify-between ">
					<MobileMenuButton handler={setShowMobileMenu} />
					<PrimaryNav routes={routes} />
					<SecondaryNav routes={userRoutes} />
					{showMobileMenu && <MobileMenu routes={routes} />}
				</div>
			</div>
		</nav>
	);
}
