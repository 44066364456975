import {Icons, Images} from "../../assets";
import Room from "./room";

const roomFeatures = [
	{icon: Icons.Area, title: "40 m²"},
	{icon: Icons.AC, title: "Air conditioning"},
	{icon: Icons.EnsuiteBathroom, title: "Ensuite bathroom"},
	{icon: Icons.FlatTV, title: "Flat-screen TV"},
	{icon: Icons.SoundProofing, title: "Soundproofing"},
	{icon: Icons.FreeWifi, title: "Free WiFi"}
];

const bathroomFeatures = [
	{icon: Icons.Toileteries, title: "Free toiletries"},
	{icon: Icons.Shower, title: "Shower"},
	{icon: Icons.Toilet, title: "Toilet"},
	{icon: Icons.Towel, title: "Towels"},
	{icon: Icons.Slippers, title: "Slippers"},
	{icon: Icons.ToiletPapers, title: "Toilet paper"}
];

const amenities = [
	{icon: Icons.Tick, title: "Upper floors accessible by stairs only"},
	{icon: Icons.Tick, title: "Linen"},
	{icon: Icons.Tick, title: "Extra long beds"},
	{icon: Icons.Tick, title: "Dressing room"},
	{icon: Icons.Tick, title: "Alarm clock"},
	{icon: Icons.Tick, title: "Wardrobe or closet"},
	{icon: Icons.Tick, title: "Hand sanitiser"},
	{icon: Icons.Tick, title: "Child safety socket covers"},
	{icon: Icons.Tick, title: "Baby safety gates"},
	{icon: Icons.Tick, title: "Air conditioning"},
	{icon: Icons.Tick, title: "Safety deposit box"},
	{icon: Icons.Tick, title: "Tile/marble floor"},
	{icon: Icons.Tick, title: "Soundproofing"},
	{icon: Icons.Tick, title: "Pants press"},
	{icon: Icons.Tick, title: "Private entrance"},
	{icon: Icons.Tick, title: "Fan"},
	{icon: Icons.Tick, title: "Dining table"},
	{icon: Icons.Tick, title: "Cleaning products"},
	{icon: Icons.Tick, title: "Desk"},
	{icon: Icons.Tick, title: "Seating Area"},
	{icon: Icons.Tick, title: "Dining area"},
	{icon: Icons.Tick, title: "TV"},
	{icon: Icons.Tick, title: "Telephone"},
	{icon: Icons.Tick, title: "Satellite channels"},
	{icon: Icons.Tick, title: "Radio"},
	{icon: Icons.Tick, title: "Flat-screen TV"},
	{icon: Icons.Tick, title: "Cable channels"},
	{icon: Icons.Tick, title: "Balcony"},
	{icon: Icons.Tick, title: "Air purifiers"},
	{icon: Icons.Tick, title: "Socket near the bed"},
	{icon: Icons.Tick, title: "Clothes rack"},
	{icon: Icons.Tick, title: "Wake up service/Alarm clock"},
	{icon: Icons.Tick, title: "Wake-up service"},
	{icon: Icons.Tick, title: "Single-room air conditioning"}
];

export default function SuperDeluxeRoom(props: {className?: string}) {
	return (
		<Room
			className={props.className}
			title="SUPER DELUXE ROOM"
			price="2200/Night"
			subTitle="Make your trip luxurious memorable"
			desc="We have total 6 Super Deluxe rooms, with all the modern amenities. Super Deluxe Rooms are well spaced out with luxurious comfort with individual balcony. Bathrooms have 24 hrs. hot & cold running water with modern amenities."
			imageLarge={Images.DeluxeRoom11}
			images={[Images.DeluxeRoom6, Images.DeluxeRoom7, Images.DeluxeRoom8, Images.DeluxeRoom9, Images.DeluxeRoom10]}
			roomFeatures={roomFeatures}
			bathroomFeatures={bathroomFeatures}
			roomAmenities={amenities}
		/>
	);
}
