import AC from "./ac.svg";
import Area from "./area.svg";
import EnsuiteBathroom from "./ensuite-bathroom.svg";
import FlatTV from "./flat-tv.svg";
import FreeWifi from "./free-wifi.svg";
import MarkerHMR from "./gicon-hmr.svg";
import Marker from "./marker.svg";
import Notification from "./notification.svg";
import Shower from "./shower.svg";
import Slippers from "./slippers.svg";
import SoundProofing from "./soundproofing.svg";
import Tick from "./tick.svg";
import ToiletPapers from "./toilet-papers.svg";
import Toilet from "./toilet.svg";
import Toileteries from "./toileteries.svg";
import Towel from "./towel.svg";
import WashingMachineWhite from "./washing-machine-white.svg";
import WashingMachine from "./washing-machine.png";
import Washing from "./washing.png";

const Icons = {
	Notification,
	Washing,
	WashingMachine,
	WashingMachineWhite,
	Marker,
	MarkerHMR,
	Tick,
	Toileteries,
	Shower,
	Toilet,
	Towel,
	Area,
	AC,
	EnsuiteBathroom,
	FlatTV,
	SoundProofing,
	FreeWifi,
	Slippers,
	ToiletPapers
};

export default Icons;
