import { Images } from "../assets";
import Footer from "../components/footer";

export default function NotFound() {
	return (
		<>
			<div className="page bg-gray-200 dark:bg-gray-300">
				<section className="py-10">
					<div className="text-center mx-auto flex-col items-center justify-center">
						<img src={Images.PageNotFound} className="w-full max-w-[600px] my-2 mx-auto" />
						<h1 className="sm:text-3xl lg:text-6xl font-extrabold text-gray-900 dark:text-red-600">404: Page Not Found</h1>
					</div>
				</section>
				<section>
					<Footer />
				</section>
			</div>
		</>
	);
}
