import {useEffect, useState} from "react";

interface MobileMenuButtonProps {
	handler(isOpened: boolean): void;
}

export default function MobileMenuButton({handler}: MobileMenuButtonProps) {
	const [isClicked, setIsClicked] = useState(false);
	const onClickHandler = () => {
		setIsClicked(!isClicked);
	};
	useEffect(() => {
		if (handler) {
			handler(isClicked);
		}
	}, [isClicked, handler]);
	return (
		<div className="absolute inset-y-0 left-0 flex items-center sm:hidden">
			{/* <!-- Mobile menu button--> */}
			<button
				type="button"
				className="inline-flex items-center justify-center p-2 rounded-md text-primary-400 hover:text-white hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
				aria-controls="mobile-menu"
				aria-expanded="false"
				onClick={onClickHandler}>
				<span className="sr-only">Open main menu</span>
				{/* <!-- Icon when menu is closed. Heroicon name: outline/menu. Menu open: "hidden", Menu closed: "block" --> */}
				<svg
					className="block h-6 w-6"
					xmlns="http://www.w3.org/2000/svg"
					fill="none"
					viewBox="0 0 24 24"
					strokeWidth="2"
					stroke="currentColor"
					aria-hidden="true">
					<path strokeLinecap="round" strokeLinejoin="round" d="M4 6h16M4 12h16M4 18h16" />
				</svg>
				{/* <!-- Icon when menu is open. Heroicon name: outline/x. Menu open: "block", Menu closed: "hidden" --> */}
				<svg
					className="hidden h-6 w-6"
					xmlns="http://www.w3.org/2000/svg"
					fill="none"
					viewBox="0 0 24 24"
					strokeWidth="2"
					stroke="currentColor"
					aria-hidden="true">
					<path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
				</svg>
			</button>
		</div>
	);
}
