import { Link } from "react-router-dom";
import { Logos } from "./../../assets";
import Icon from "./../../components/common/icon";
import "./../../styles/navbar.css";

export type Route = {
	title: string;
	url: string;
};

export type RouteProps = {
	routes: Route[];
};

export type FooterProps = {
	routes?: Route[];
};

const RoutesList = [
	{title: "Home", url: "/"},
	{title: "Amenities", url: "/amenities"},
	{title: "Rooms And Tariffs", url: "/rooms-and-tariffs"},
	{title: "Gallery", url: "/gallery"},
	{title: "Locations", url: "/locations"},
	{title: "Contact Us", url: "/contact-us"}
];

export default function Footer({routes = RoutesList}: FooterProps) {
	return (
		<div className="footer w-full py-10 bg-primary-700 dark:bg-gray-700 text-white">
			<div className="max-w-7xl mx-auto px-2 sm:px-6 lg:px-8 space-y-4">
				<div className="flex flex-col items-center lg:flex-row lg:items-start lg:justify-between lg:space-x-20 space-y-4 lg:space-y-0">
					<div className="logo flex-none flex flex-col items-center space-y-4">
						<img src={Logos.IconColor} />
						<img className="w-[150px]" src={Logos.TextWhite} />
						<h2 title="Guest is God" className="w-full max-w-[200px] text-center text-lg border-t-2 border-secondary pt-2">
							।। अतिथि देवो भवः ।।
						</h2>
					</div>
					<div className="flex-1 flex flex-col items-start space-y-10 lg:space-y-0 lg:flex-row lg:items-stretch lg:justify-between">
						<div className="routes flex-[0.22] flex flex-col items-start">
							<div className="font-bold text-secondary mb-2">Useful Links</div>
							<div className="flex flex-row flex-wrap lg:flex-col space-y-1 px-4">
								{routes.map((item, index) => (
									<Link className="w-1/2 lg:w-full text-left text-base text-white" key={index} to={item.url}>
										{item.title}
									</Link>
								))}
							</div>
						</div>
						<div className="followUs flex-[0.22] flex flex-col items-start space-y-2">
							<div className="font-bold mb-2 text-secondary">Follow Us:</div>
							<div className="flex flex-row flex-wrap lg:flex-col space-y-1 px-4">
								<a
									target="_blank"
									href="https://api.whatsapp.com/message/QPTWE3CNK7MXH1"
									className="whatsapp flex flex-row items-baseline w-1/2"
									rel="noreferrer">
									<Icon type="FontAwesome" name="fa-brands fa-whatsapp" size="1x" className="text-secondary" />
									<span className="ml-2">Whatsapp</span>
								</a>
								<a
									target="_blank"
									href="https://www.facebook.com/mayankresidencyhotel"
									className="facebook flex flex-row items-baseline w-1/2"
									rel="noreferrer">
									<Icon type="FontAwesome" name="fa-brands fa-facebook" size="1x" className="text-secondary" />
									<span className="ml-2">Facebook</span>
								</a>
								<a
									target="_blank"
									href="https://twitter.com/MayankHotel"
									className="twitter flex flex-row items-baseline w-1/2"
									rel="noreferrer">
									<Icon type="FontAwesome" name="fa-brands fa-twitter" size="1x" className="text-secondary" />
									<span className="ml-2">Twitter</span>
								</a>
								<a
									target="_blank"
									href="https://www.instagram.com/hotelmayankresidency/"
									className="instagram flex flex-row items-baseline w-1/2"
									rel="noreferrer">
									<Icon type="FontAwesome" name="fa-brands fa-instagram" size="1x" className="text-secondary" />
									<span className="ml-2">Instagram</span>
								</a>

								<a
									target="_blank"
									href="https://www.linkedin.com/in/hotel-mayank-residency-07624946/"
									className="linkedin flex flex-row items-baseline w-1/2"
									rel="noreferrer">
									<Icon type="FontAwesome" name="fa-brands fa-linkedin" size="1x" className="text-secondary" />
									<span className="ml-2">Linkedin</span>
								</a>
								<a
									target="_blank"
									href="https://www.youtube.com/channel/UClUhteuCYH8vwrT7gzGmPNQ?view_as=subscriber"
									className="youtube flex flex-row items-baseline w-1/2"
									rel="noreferrer">
									<Icon type="FontAwesome" name="fa-brands fa-youtube" size="1x" className="text-secondary" />
									<span className="ml-2">YouTube</span>
								</a>
								<a
									target="_blank"
									href="https://t.me/mayankresidency"
									className="telegram flex flex-row items-baseline w-1/2"
									rel="noreferrer">
									<Icon type="FontAwesome" name="fa-brands fa-telegram" size="1x" className="text-secondary" />
									<span className="ml-2">Telegram</span>
								</a>
							</div>
						</div>
						<div className="about flex-[0.46] flex flex-col items-start space-y-2">
							<div className="font-bold mb-2 text-secondary">Contact Info</div>
							<div className="flex flex-col space-y-2 px-4">
								<div className="email flex-row space-x-3">
									<Icon type="FontAwesome" name="envelope" size="1x" className="text-secondary" />
									<a href="mailto:hotelmayankresidency@gmail.com">hotelmayankresidency@gmail.com</a>
								</div>
								<div className="phone flex-row space-x-3">
									<Icon type="FontAwesome" name="phone" size="1x" className="text-secondary" />
									<div className="inline-flex flex-row space-x-2">
										<a href="tel:+911126784162">+91-11-26784162</a>,<a href="tel:+919999874162">+91-9999874162</a>
									</div>
								</div>
								<div className="location flex-row space-x-3">
									<Icon type="FontAwesome" name="location" size="1x" className="text-secondary" />
									<a href="https://g.page/hotel-mayank-residency?share" target="_blank" rel="noreferrer">
										A-230, Rd Number 2, near IGI Airport,
										<br />
										&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;A Block, Mahipalpur, New Delhi-110037
									</a>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}
