import {library} from "@fortawesome/fontawesome-svg-core";
import {fab} from "@fortawesome/free-brands-svg-icons";
import {far} from "@fortawesome/free-regular-svg-icons";
import {fas} from "@fortawesome/free-solid-svg-icons";
import {ToastContainer} from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import {BrowserRouter, Route, Routes} from "react-router-dom";
import Navbar from "./components/navbar/index";
import {Amenities, ContactUs, Gallery, Home, Location, NotFound, RoomsAndTariffs} from "./pages/index";
import "./styles/app.css";
library.add(fas, far, fab);

export default function MainApp() {
	return (
		<>
			<BrowserRouter>
				<div className="app">
					<Navbar />
					<Routes>
						<Route path="/" element={<Home />} />
						<Route path="amenities" element={<Amenities />} />
						<Route path="rooms-and-tariffs" element={<RoomsAndTariffs />} />
						<Route path="gallery" element={<Gallery />} />
						<Route path="location" element={<Location />} />
						<Route path="contact-us" element={<ContactUs />} />
						{/* 👇️ only match this when no other routes match */}
						<Route path="*" element={<NotFound />} />
					</Routes>
					<ToastContainer />
				</div>
			</BrowserRouter>
		</>
	);
}
