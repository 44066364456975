import {Booking} from "./../../components/booking";
import Footer from "./../../components/footer";
import MasterHeads from "./../../components/masterheads";
import "./../../styles/rooms.css";
import DeluxeRoom from "./deluxe-room";
import FamilyRoom from "./family-room";
import SuperDeluxeRoom from "./super-deluxe-room";

export default function RoomsAndTariffs() {
	return (
		<>
			<div className="page">
				<div className="wrapper">
					<section className="relative w-full">
						<MasterHeads.SimpleWithContent className="h-[60vh] lg:h-[80vh]" />
						<Booking />
					</section>

					<div className="relative py-12 w-full ">
						<div className="flex flex-row flex-wrap items-stretch justify-start">
							<FamilyRoom className="px-4 bg-white dark:bg-gray-700" />
							<SuperDeluxeRoom className="px-4 bg-gray-200 dark:bg-gray-600" />
							<DeluxeRoom className="px-4 bg-white dark:bg-gray-700" />
						</div>
					</div>

					<section>
						<Footer />
					</section>
				</div>
			</div>
		</>
	);
}
