import Images from "../../assets/images/index";
import { Booking } from "./../../components/booking/index";
import Footer from "./../../components/footer/index";
import MasterHeads from "./../../components/masterheads";
import "./../../styles/rooms.css";

export default function ContactUs() {
	return (
		<>
			<div className="page">
				<div className="wrapper">
					<section className="relative w-full">
						<MasterHeads.SimpleWithContent className="h-[60vh] lg:h-[80vh]" />
						<Booking />
					</section>

					<section>
						<div className="relative pt-24 pb-12 w-full">
							<div className="absolute -z-10 top-0 left-0 w-white w-screen h-screen">
								<img className="w-full h-full object-cover object-top-left" src={Images.ContactUsBsSvg} />
							</div>

							<div className="max-w-7xl mx-auto">
								<div className="flex items-center justify-center lg:justify-end">
									<div className="bg-white rounded-lg shadow-[0_0_50px_1px_rgba(0,0,0,0.3)] px-2 py-2 lg:px-2 lg:py-2 w-3/4 lg:w-[450px] lg:mr-[100px]">
										<img className="w-full h-full object-cover" src={Images.WhatsAppConnect} />
										{/* <ContactUsForm
											title="SEND US MESSAGE"
											titleStyle="w-full text-center font-bold text-primary underline"
											className=""
										/> */}
									</div>
								</div>
							</div>
						</div>
					</section>

					<section>
						<Footer />
					</section>
				</div>
			</div>
		</>
	);
}
