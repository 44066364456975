import { useEffect, useState } from "react";
export type ThemeChangedEvent = {
	isDark: boolean;
	mode: "light" | "dark";
};

export function useDarkMode() {
	const [theme, setTheme] = useState(localStorage.theme);
	const mode = theme === "dark" ? "light" : "dark";
	const isDark = theme === "dark";

	useEffect(() => {
		const root = window.document.documentElement;
		root.classList.remove(mode);
		root.classList.add(theme);
		localStorage.setItem("theme", theme);
		window.dispatchEvent(new CustomEvent("themeChanged", {detail: {isDark, theme}}));
	}, [theme, mode, isDark]);

	return [isDark, mode, setTheme] as const;
}

export function useTheme() {
	const [dark] = useDarkMode();
	const [isDark, setIsDark] = useState(dark);
	const [mode, setMode] = useState(isDark);
	// console.log("Location:", location.pathname);
	useEffect(() => {
		const unsubscribe = window.addEventListener("themeChanged", (event) => {
			const {isDark, theme} = (event as CustomEvent).detail;
			// console.log("ThemeChanged:", isDark, theme);
			setIsDark(isDark);
			setMode(theme);
		});
		return () => unsubscribe;
	}, []);

	return [isDark, mode] as const;
}

