import GoogleMap from "./../../components/common/google-map";
import Footer from "./../../components/footer/index";

// const {GOOGLE_MAP_API_KEY} = process.env;
// console.log("API Key:", GOOGLE_MAP_API_KEY);

export default function Location() {
	return (
		<>
			<div className="page">
				<div className="wrapper">
					<section className="w-full relative h-[60vh] lg:h-[80vh]">
						<GoogleMap
							apiKey="AIzaSyAGUGC131UXtbVWFy6nEvEzQri7y9wKOoY"
							options={{
								center: {lat: 28.5477041, lng: 77.1270134},
								zoom: 18,
								locations: [{id: "1", location: {lat: 28.5477041, lng: 77.1270134}, text: "Hotel Mayank Residency"}]
							}}
						/>
					</section>
					<section></section>

					<section>
						<Footer />
					</section>
				</div>
			</div>
		</>
	);
}
