import BathroomFeatures from "./bathroom-features";
import RoomAmenities from "./room-amenities";
import RoomFeatures from "./room-features";

export interface IRoomFeature {
	icon: string;
	title: string;
}

interface RoomProps {
	title: string;
	subTitle: string;
	className?: string;
	desc: string;
	imageLarge: string;
	images: string[];
	roomFeatures: IRoomFeature[];
	bathroomFeatures: IRoomFeature[];
	roomAmenities: IRoomFeature[];
	price: string;
}

export default function Room(props: RoomProps) {
	const {title, price, subTitle, className = "", desc} = props;
	const {imageLarge, images} = props;
	const {roomFeatures, bathroomFeatures, roomAmenities} = props;
	return (
		<div className={`relative w-full py-4 lg:py-10 ${className}`}>
			<div className="max-w-7xl mx-auto">
				<div className="relative w-full flex flex-col items-start justify-start space-y-2">
					<div className="w-full flex flex-row items-center justify-between">
						<h1 className="font-bold text-xl lg:text-3xl text-secondary dark:text-white">{title}</h1>
						<h1 className="font-bold text-xl lg:text-3xl text-primary dark:text-secondary whitespace-nowrap">₹ {price}</h1>
					</div>
					<h3 className="text-xl italic text-primary dark:text-gray-300">{subTitle}</h3>
					<div className="flex flex-col lg:flex-row items-start justify-start">
						<div className="relative flex-[0.6] flex-col space-y-3 mb-3 lg:mb-0">
							<p className="text-lg dark:text-gray-300">{desc}</p>

							<div className="w-full flex flex-row items-start justify-between">
								<RoomFeatures features={roomFeatures} className="w-full lg:w-1/2" />
								<BathroomFeatures features={bathroomFeatures} className="w-full lg:w-1/2" />
							</div>
						</div>
						<div className="flex-[0.4] flex-col ">
							<div className="w-full">
								<img src={imageLarge} />
							</div>
						</div>
					</div>
					<div className="relative w-full flex flex-col lg:flex-row items-start justify-start lg:space-x-10">
						<div className="flex-[0.5] flex-col space-y-3">
							<div className="w-full flex-1 flex flex-row flex-wrap my-4">
								{images.map((item, index) => (
									<img className="w-1/2 lg:w-1/3" key={index} src={item} />
								))}
							</div>
						</div>
						<div className="flex-[0.5] flex-col">
							<RoomAmenities className="w-full my-2 justify-between" features={roomAmenities} />
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}
