import { useTheme } from "../../hooks/useDarkMode";
import { Images } from "./../../assets";
export function Glance() {
	const [dark] = useTheme();
	return (
		<div
			className="glance w-full py-10 lg:py-20 bg-gray-200"
			style={{
				backgroundImage: `url(${dark ? Images.DarkCircles : Images.BluePink})`,
				backgroundPosition: "center",
				backgroundSize: "cover"
			}}>
			<div className="max-w-7xl mx-auto px-2 sm:px-6 lg:px-8">
				<h3 className="font-bold text-secondary">A Glance</h3>
				<h2 className="font-extrabold text-3xl text-primary dark:text-white flex flex-col mb-6">
					{`${new Date().getFullYear() - 2010}+ Years of Hospitality Experience`}
				</h2>
				<div className="flex flex-col justify-between space-y-4">
					<div className="w-full flex flex-col lg:flex-row items-start justify-start lg:space-x-8">
						<div className="w-full flex-1 flex flex-col lg:flex-row lg:space-x-2 space-y-2 lg:space-y-0">
							<img className="flex-1" src={Images.HomeAbout1} />
							<img className="flex-1" src={Images.HomeAbout2} />
						</div>

						<p className="flex-1 text-lg dark:text-white mb-6">
							Situated at a stone throw distance from Domestic & International Airport at Mahipalpur, New Delhi on National
							Highway 8 (NH-8), <span className="font-bold">Hotel Mayank Residency</span> have more than
							{`${new Date().getFullYear() - 2010}+`} years of experience in hotel industry with serving thousands of happy
							&amp; satisfied customers.
						</p>
					</div>
					<div className="w-full flex flex-col lg:flex-row items-start justify-start lg:space-x-8">
						<div className="flex-1 flex flex-row">
							<img className="flex-2 w-full mt-2" src={Images.HomeAbout3} />
						</div>
						<p className="flex-1 text-lg dark:text-white mb-6">
							Hotel has private parking and a shared lounge. Provides Airport pickup and drop, 24-hour front desk, room service
							and currency exchange for our guests. <br />
							<br />
							We serves as a Royal for business class and holiday travellers. The location also make it easily reachable where
							you can ever be sure that your trip always be a pleasure.
						</p>
					</div>
				</div>
			</div>
		</div>
	);
}
