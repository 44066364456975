import FullBlack from "./logo-full-black.svg";
import FullRedBlue from "./logo-full-red-blue.svg";
import FullRedWhite from "./logo-full-red-white.svg";

import FullWhite from "./logo-full-white.svg";
import IconBlack from "./logo-icon-black.svg";
import IconColor from "./logo-icon-color.svg";
import IconWhite from "./logo-icon-white.svg";

import TextBlue from "./logo-text-blue.svg";
import TextWhite from "./logo-text-white.svg";

const Logos = {
	TextWhite,
	FullBlack,
	FullRedBlue,
	FullRedWhite,
	FullWhite,
	IconBlack,
	IconColor,
	IconWhite,
	TextBlue
};

export default Logos;
