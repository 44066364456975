import {Icons} from "../../assets";
import {IRoomFeature} from "./room";

const Feature = (props: {icon?: string; title?: string}) => {
	const {icon = Icons.Tick, title = "Hello"} = props;
	return (
		<div className="flex flex-row items-center space-x-2">
			<img className="w-3 h-3" src={icon} />
			<span className="text-md dark:text-gray-300 pr-5 inline-block whitespace-nowrap">{title}</span>
		</div>
	);
};
export default function RoomAmenities(props: {className?: string; features: IRoomFeature[]}) {
	const {className = "", features} = props;
	return (
		<div className={`flex flex-col space-y-1 ${className}`}>
			<h2 className="font-bold dark:text-white">Room Amenities:</h2>
			<div className="flex flex-row flex-wrap items-start justify-start">
				{features.map((item, index) => (
					<Feature title={item.title} icon={item.icon} key={index} />
				))}
			</div>
		</div>
	);
}
