import {Booking} from "./../../components/booking/index";
import Footer from "./../../components/footer/index";
import MasterHeads from "./../../components/masterheads";
import "./../../styles/home.css";
import {Amenities} from "./amenities";
import {Glance} from "./glance";
import {TrustQuality} from "./trust-quality";

export default function Home() {
	return (
		<>
			<div className="page">
				<div className="wrapper">
					<section className="relative w-full]">
						<MasterHeads.SimpleWithContent className="h-[60vh] lg:h-[80vh]" />
						<Booking />
					</section>
					<section>
						<TrustQuality />
					</section>
					<section>
						<Amenities />
					</section>
					<section>
						<Glance />
					</section>
					<section>
						<Footer />
					</section>
				</div>
			</div>
		</>
	);
}
