import {Images} from "./../../assets";
// import "styles/masterheads.css";

interface PropTypes {
	className?: string;
}

export default function SimpleWithContent(props: PropTypes) {
	const {className = ""} = props;
	return (
		<>
			<div className={`masterhead ${className}`}>
				<div className="h-full">
					<img className="w-full h-full object-cover" src={Images.RoomFrontShot} alt="" />
				</div>
			</div>
		</>
	);
}
