// import "./../../styles/gallery.css";

export interface IGalleryItem {
	image: string;
	title?: string;
}

export default function ImageViewer(props: {images: IGalleryItem[]; className?: string}) {
	const {images, className = ""} = props;
	return (
		<ul className={`w-full flex flex-row h-[450px] mx-auto ${className}`}>
			{images.map((item, index) => {
				return (
					<li
						className="relative flex-1 shadow-xl overflow-hidden w-auto hover:flex-[6] transition-all duration-200"
						key={index}>
						{item.title && <span>New York</span>}
						<img
							className="absolute p-1 bg-white left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 object-cover w-full h-full"
							src={item.image}
						/>
					</li>
				);
			})}
		</ul>
	);
}
