import Loader from "./loading.webp";

import HomeAbout1 from "./hmr-about-1.jpg";
import HomeAbout2 from "./hmr-about-2.jpg";
import HomeAbout3 from "./hmr-about-3.jpg";
import HomeBig1 from "./hmr-big-1.jpg";
import HomeBig2 from "./hmr-big-2.jpg";
import HomeSpace1 from "./hmr-space-1.jpg";
import HomeSpace2 from "./hmr-space-2.jpg";

import AmenitiesBG from "./amenities-bg.jpg";
import RoomFrontShot from "./hmr-room-front-shot.jpg";

import ContactUsBs from "./contact-us-bs.jpg";
import ContactUsBsSvg from "./contact-us-bs.svg";
import ContactUs from "./contact-us.svg";
import WhatsAppConnect from "./whatsapp-connect.jpg";

import AirportPickup from "./airport_pickup.jpg";
import Building from "./building.jpg";
import CleanedAcRoom from "./cleaned_ac_room.jpg";
import Currency from "./currency_exchange.jpg";
import DoctorOnCall from "./doctor_on_call.jpg";
import Enterance from "./enterance.jpg";
import FreeWifi from "./free-wifi-architecture.jpg";
import LaundryOnRequest from "./laundary_on_request.jpg";
import Lobby from "./lobby.jpg";
import LockerFacility from "./locker_facility.jpg";
import PowerBackup from "./power_backup.jpg";
import ValetParking from "./valet_parking.jpg";
// Backgrounds
import PageNotFound from "./404-error.png";
import AgeLines from "./agelines.jpg";
import GrayDots from "./background-gray-dots.jpg";
import Beach from "./beach.jpg";
import BluePink from "./blue-pink-merge.jpg";
import BlueVintage from "./blue-vintage.jpg";
import BrownVintage from "./brown-vintage.jpg";
import ColorDots from "./color-dots.jpg";
import DarkCircles from "./dark-circles.jpg";
import DarkSky from "./dark-sky.jpg";
import Bawri from "./delhi-bawri.jpg";
import DelhiMagicNature from "./delhi-magic-nature.jpg";
import GrayBG1 from "./grey-background-34160.jpg";
import HalftoneDots from "./halftone-dots-background.jpg";
import HandDrawnGray from "./hand-drawn-gray.jpg";
import HandDrawnWideGray from "./hand-drawn-wide-gray.jpg";
import HandDrawnWide from "./hand-drawn-wide.jpg";
import HandDrawn from "./hand-drawn.jpg";
import NasaUnsplash from "./nasa-unsplash.jpg";
import DarkRoad from "./road-dark.jpg";
import BeachSand from "./sand-beach.jpg";
import SummerBeach from "./summer-beach-background-vector.jpg";

import FamilyRoom1 from "./family-room-1.jpg";
import FamilyRoom2 from "./family-room-2.jpg";
import FamilyRoom3 from "./family-room-3.jpg";
import FamilyRoom4 from "./family-room-4.jpg";
import FamilyRoom5 from "./family-room-5.jpg";
import FamilyRoom6 from "./family-room-6.jpg";
import FamilyRoom7 from "./family-room-7.jpg";
import FamilyRoom8 from "./family-room-8.jpg";

import DeluxeRoom1 from "./deluxe-room-1.jpg";
import DeluxeRoom10 from "./deluxe-room-10.jpg";
import DeluxeRoom11 from "./deluxe-room-11.jpg";
import DeluxeRoom2 from "./deluxe-room-2.jpg";
import DeluxeRoom3 from "./deluxe-room-3.jpg";
import DeluxeRoom4 from "./deluxe-room-4.jpg";
import DeluxeRoom5 from "./deluxe-room-5.jpg";
import DeluxeRoom6 from "./deluxe-room-6.jpg";
import DeluxeRoom7 from "./deluxe-room-7.jpg";
import DeluxeRoom8 from "./deluxe-room-8.jpg";
import DeluxeRoom9 from "./deluxe-room-9.jpg";

const Home = {
	RoomFrontShot,
	HomeBig1,
	HomeBig2,
	HomeSpace1,
	HomeSpace2,
	HomeAbout1,
	HomeAbout2,
	HomeAbout3,
	AmenitiesBG
};

const FamilyRooms = {
	FamilyRoom1,
	FamilyRoom2,
	FamilyRoom3,
	FamilyRoom4,
	FamilyRoom5,
	FamilyRoom6,
	FamilyRoom7,
	FamilyRoom8
};

const DeluxeRooms = {
	DeluxeRoom1,
	DeluxeRoom2,
	DeluxeRoom3,
	DeluxeRoom4,
	DeluxeRoom5,
	DeluxeRoom6,
	DeluxeRoom7,
	DeluxeRoom8,
	DeluxeRoom9,
	DeluxeRoom10,
	DeluxeRoom11
};

const Backgrounds = {
	GrayBG1,
	BrownVintage,
	BlueVintage,
	HandDrawn,
	GrayDots,
	BluePink,
	Beach,
	BeachSand,
	ColorDots,
	Bawri,
	HalftoneDots,
	AgeLines,
	DarkCircles,
	SummerBeach,
	HandDrawnWide,
	DarkSky,
	NasaUnsplash,
	DarkRoad,
	DelhiMagicNature,
	HandDrawnWideGray,
	HandDrawnGray,
	PageNotFound
};

const Images = {
	Loader,
	ContactUs,
	ContactUsBs,
	ContactUsBsSvg,
	FreeWifi,
	Currency,
	PowerBackup,
	CleanedAcRoom,
	AirportPickup,
	ValetParking,
	LaundryOnRequest,
	DoctorOnCall,
	LockerFacility,
	Building,
	Enterance,
	Lobby,
	WhatsAppConnect,
	...Home,
	...Backgrounds,
	...FamilyRooms,
	...DeluxeRooms
};

export const BackgroundStyle = (options:{image: string, size?:string, repeat?:string, position?:string}) => {
	const {image, position="center", repeat="repeat", size="100%" } = options;
	return {
		backgroundImage: image,
		backgroundPosition: position,
		backgroundRepeat: repeat,
		backgroundSize: size
	}
};

export default Images;
