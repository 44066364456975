import { useCallback } from "react";
import { DarkModeSwitch } from "react-toggle-dark-mode";
import { useDarkMode } from "./../../hooks/useDarkMode";

interface SwitchDarkModeProps {
	className?: string;
	lightLabel?: string;
	darkLabel?: string;
	showLabel?: boolean;
	iconSize?: number;	
}
export default function SwitchDarkMode({
	className = "",
	lightLabel = "Light Mode",
	darkLabel = "Dark Mode",
	showLabel = false,
	iconSize = 24
}: SwitchDarkModeProps) {
	const [isDark, theme, setTheme] = useDarkMode();
	const onClickHandler = useCallback(()=>{		
		setTheme((mode:string) => mode === "dark" ? "light" : "dark");
	},[setTheme]) 
	return (
		<div			
			className={`inline-flex flex-row justify-center items-center ${className} ${theme}`}>
			<DarkModeSwitch sunColor="#EE1E3E" checked={isDark} onChange={onClickHandler} size={iconSize} />
			{showLabel && (
				<h3 className="cursor-pointer text-grey-800 dark:text-white ml-2">{isDark === true ? darkLabel : lightLabel}</h3>
			)}
		</div>
	);
}
