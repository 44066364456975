import {useState} from "react";
import Icon from "./../../components/common/icon";
import SwitchDarkMode from "./../../components/common/switch-dark-mode";
import type {Route} from "./index";

export type SecondaryNavProps = {
	routes: Route[];
};
export default function SecondaryNav({routes}: SecondaryNavProps) {
	const [userMenuVisible, setUserMenuVisible] = useState(false);
	const [isClicked, setIsClicked] = useState(false);

	return (
		<div className="absolute inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0">
			{/* <!-- Heroicon name: outline/bell --> */}
			{/* <div className="px-2 text-gray-900 dark:text-gray-100">
				<Icon
					name={"fa-bell" as IconProp}
					hoverSound={Sounds.BellShake}
					toggleClass="fa-shake"
					className="cursor-pointer"
				/>
			</div> */}

			{/* <!-- Profile dropdown --> */}
			{/* <div className="ml-2 relative">
				<div>
					<div
						id="user-menu-button"
						className="bg-gray-800 cursor-pointer relative flex text-sm rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white"
						aria-expanded="false"
						aria-haspopup="true"
						onClick={() => setIsClicked(!isClicked)}
						onMouseEnter={() => setUserMenuVisible(true)}
						onMouseLeave={() => setUserMenuVisible(false)}>
						<span className="sr-only">Open user menu</span>
						<img
							className="h-8 w-8 rounded-full"
							src="https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
							alt=""
						/>
						{(userMenuVisible || isClicked) && (
							<UserMenu className={"mt-8 right-0 z-20"} triggerId="user-menu-button" routes={routes} />
						)}
					</div>
				</div>
			</div> */}
			<Icon type="Custom" name="dark-mode" className="ml-2">
				<SwitchDarkMode iconSize={30} />
			</Icon>
		</div>
	);
}
