import { Link, useLocation } from "react-router-dom";
import { Logos } from "./../../assets";
import { useTheme } from "./../../hooks/useDarkMode";
import "./../../styles/navbar.css";
import type { RouteProps } from "./index";

export default function PrimaryNav({routes = []}: RouteProps) {
	const [isDark] = useTheme();
	const location = useLocation();	
	return (
		<div className="primary">
			<div className="logo flex-shrink-0 flex items-center">
				<Link to="/" title="।। अतिथि देवो भवः ।।">
					<img
						className="hidden lg:hidden h-12 w-auto"
						src={isDark === true ? Logos.IconWhite : Logos.IconColor}
						alt="Brand Logo"
					/>
					<img
						className={"block h-12 w-auto"}
						src={isDark === true ? Logos.FullRedWhite : Logos.FullRedBlue}
						alt="Mayank Residency"
					/>
				</Link>
			</div>
			<div className="hidden sm:block sm:ml-6 flex-1">
				<div className="flex space-x-1 items-center justify-end">
					{/* <!-- Current: "bg-gray-900 text-white", Default: "text-gray-300 hover:bg-gray-700 hover:text-white" --> */}
					{routes.map(({title, url}, index) => {
						return (
							<Link
								key={index}
								to={url}
								className={`link ${location.pathname === url ? "active" : ""}`}
								aria-current={location.pathname === url}>
								{title}
							</Link>
						);
					})}
				</div>
			</div>
		</div>
	);
}
